.dropdown {
  position: absolute;
  right: 0;
  top: 120%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
}
.dropdown-list {
  width: 80px;
}
ul .iconDropdown-item {
  font-weight: 500;
  border-bottom: 1px solid lightgray;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
ul .iconDropdown-item:last-child {
  border: none;
}
@media only screen and (min-width: 1024px) {
  ul .iconDropdown-item:hover {
    background: rgb(236, 236, 236);
  }
}
ul .iconDropdown-item.active {
  background: #f97316;
  color: white;
}
