.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
}
.authentication-wrapper.authentication-cover {
  align-items: flex-start;
}
.auth-cover-brand {
  position: absolute;
  z-index: 1;
  inset-block-start: 1rem;
}
.divider {
  display: block;
  text-align: center;
  margin: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
}
.light-style .authentication-wrapper .authentication-bg {
  background-color: #fff;
}
.w-px-400 {
  width: 400px !important;
}
.app-brand-text {
  flex-shrink: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.authentication-wrapper .authentication-inner {
  width: 100%;
}
.icon-span {
  color: #828393;
  background-color: transparent;
}
.input-group {
  display: block;
}
.input-icons {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
}
@media only screen and (max-width: 1024px) {
  .authentication-wrapper {
    display: block;
    background-color: #f4f4f4;
  }
}
