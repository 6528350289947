.plan-card {
  background: #fff;
  width: 14rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 30px rgba(207, 212, 222, 0.7);
  font-family: "Poppins", sans-serif;
}

.plan-card h4 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  width: max-content;
}

.etiquet-price {
  position: relative;
  width: 13.46rem;
  margin-left: -0.65rem;
  padding: 0.2rem 1.2rem;
  border-radius: 5px 0 0 5px;
}

.etiquet-price p {
  margin: 0;
  padding-top: 0.4rem;
  display: flex;
  font-size: 17px;
  font-weight: 500;
}

.etiquet-price p:before {
  content: "₹";
  margin-right: 5px;
  font-size: 15px;
  font-weight: 300;
}

.etiquet-price div {
  position: absolute;
  bottom: -23px;
  right: 0px;
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-right: 13px solid transparent;
  z-index: -6;
}
