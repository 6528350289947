.forgotPassword-container,
.reset-container {
  width: 500px;
}
.pointer {
  cursor: pointer;
}
.resetPasswordContainer {
  height: 100vh;
}
.brand-logo {
  position: absolute;
  top: 10px;
  left: 20px;
}
.login-page-container {
  height: 100vh;
}
.login-bg-size {
  width: 55%;
}
.login-logos {
  background-color: #f3f3f3;
}
.login-logos-size {
  width: 20px;
  height: 20px;
}
