.mainSection {
  height: 100vh;
}
.remoteVideo {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.localVideo {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 250px;
  height: 150px;
  z-index: 1;
  background-color: #b02444;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.video-player {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.chat {
  position: relative;
  height: 100%;
  background-color: #17335e;
}
.messageList {
  height: calc(100vh - 85px);
  overflow: auto;
  padding: 1rem;
}
.chat-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
}
.chat-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cbd5e1;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.videoControls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 1em;
  z-index: 2;
}
.controls {
  border-radius: 50%;
  padding: 12px;
}
#user-1 {
  display: none;
}
#timer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* latest css */
#previewContainer video {
  height: 300px;
}

/* @media only screen and (max-width: 1024px) {
  .videokit {
    max-height: 700px;
  }
} */
/* @media only screen and (min-width: 1025px) {
  .videokit {
    max-height: 1080px;
  }
} */
.videokit {
  padding: 0 !important;
}
.self-view {
  max-width: 250px !important;
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 3;
  margin: 0 !important;
  border: 1px solid white;
  border-radius: 15px !important;
}
.user-view {
  margin-bottom: 0 !important;
  height: 100vh;
}
#participant-canvas {
  border-radius: 0 !important;
  height: 100vh !important;
}
.controlskit {
  margin-bottom: 0 !important;
}
app-controlskit .controlskit {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9;
  background: transparent !important;
}
app-controlskit .controlskit .controls {
  border-radius: 0px !important;
}
app-sharekit {
  display: block;
  height: 100vh !important;
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
}
app-sharekit #participants-screen-share-content-canvas {
  height: 100vh !important;
  width: 100% !important;
}
app-sharekit .row .column {
  height: 100vh !important;
}
