.sidebar-main {
  overflow-y: hidden;
  height: 77vh;
  scrollbar-width: 5px;
}
.sidebar-main:hover {
  overflow-y: auto;
}

.responsive {
  position: fixed;
  z-index: 10;
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .responsive {
    width: 40%;
    right: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .sidebar-main {
    overflow-y: auto;
    height: 67vh;
  }
}
@media only screen and (min-width: 1024px) {
  .responsive {
    position: relative;
    display: block;
    width: 100%;
  }
}
