.online-status-circle {
  width: 12px;
  height: 12px;
  background: green;
  position: absolute;
  top: 4px;
  left: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.offline-status-circle {
  width: 12px;
  height: 12px;
  background: gray;
  position: absolute;
  top: 4px;
  left: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.chat-container {
  height: calc(100vh - 180px);
}
.cstm-chat-height {
  min-height: 80%;
  height: calc(100vh - 220px);
}
