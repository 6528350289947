select:focus-visible {
  outline: 0;
}
.icon-span {
  height: 49px;
}
.role-input,
.select-input {
  padding: 0.7rem 0.7rem;
}
.register-bg-size {
  width: 80%;
}
.register-logos {
  background-color: #f3f3f3;
}
.register-logos-size {
  width: 20px;
  height: 20px;
}
