.toast-component {
  position: relative;
  border-radius: 10px;
  animation-name: slidein;
  animation-duration: 0.35s;
}
@keyframes slidein {
  0% {
    transform: translateX(100%);
  }
  60% {
    transform: translateX(-15%);
  }
  80% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}
.toast-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
