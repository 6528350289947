.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid #0d0d0d;
  }

  hr {
    border: none;
    border-top: 2px solid #0d0d0d;
    margin: 2rem 0;
  }
}
.menu-button {
  background: none;
  color: rgba(70, 70, 70);
  margin: 7px;
  margin-right: 12px;
  border-radius: 5px;
}
.text-editor {
  border: 1px solid #949494;
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
}
.editor-styles {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #949494;
  padding: 10px;
}
.ProseMirror {
  height: 300px;
  padding: 1rem;
  overflow: auto;
}
.ProseMirror:focus {
  outline: none;
}
.ProseMirror > ul {
  list-style-type: disc;
}
.ProseMirror > ol {
  list-style-type: decimal;
}
button.is-active {
  background: rgba(197, 197, 197);
  padding: 4px 5px;
  border-radius: 2px;
}

@media only screen and (min-width: 564px) {
  .editor-styles {
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .text-editor {
    width: 80%;
  }
}
@media only screen and (min-width: 1024px) {
  .text-editor {
    width: 70%;
  }
}
@media only screen and (min-width: 1350px) {
  .text-editor {
    width: 60%;
  }
}
