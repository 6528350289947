.apexcharts-legend {
  display: flex;
  overflow: auto;
  padding: 0 10px;
}
.apexcharts-legend.apx-legend-position-bottom,
.apexcharts-legend.apx-legend-position-top {
  flex-wrap: wrap;
}
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  flex-direction: column;
  bottom: 0;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  justify-content: flex-start;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: center;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
  justify-content: flex-end;
}
.apexcharts-legend-series {
  cursor: pointer;
  line-height: normal;
}
.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  display: flex;
  align-items: center;
}
.apexcharts-legend-text {
  position: relative;
  font-size: 14px;
}
.apexcharts-legend-text *,
.apexcharts-legend-marker * {
  pointer-events: none;
}
.apexcharts-legend-marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 3px;
  border-style: solid;
}

.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series,
.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: inline-block;
}
.apexcharts-legend-series.apexcharts-no-click {
  cursor: auto;
}
.apexcharts-legend .apexcharts-hidden-zero-series,
.apexcharts-legend .apexcharts-hidden-null-series {
  display: none !important;
}
.apexcharts-inactive-legend {
  opacity: 0.45;
}
.course-widget .investment-icon {
  background: linear-gradient(310.31deg, #018400 14.71%, #7ffffb 100%);
}
.cstm-pos {
  bottom: 40px !important;
  width: 90% !important;
  left: 13px !important;
}
.cstm-margin {
  margin-right: 10px;
}
.cstm-hover:hover i {
  color: #b02444 !important;
}
.dropdown-menu a {
  opacity: 1 !important;
  color: #565b65 !important;
}
.dropdown-menu li:hover a {
  background-color: #f1f0ff !important;
  color: #b02444 !important;
}
.activity-dropdown-btn {
  background-color: #7366ff !important;
  color: white !important;
}
.activity-dropdown-btn:hover {
  background-color: #f1f0ff !important;
  color: #b02444 !important;
}
.modal-padding {
  padding-right: 20px;
  padding-left: 20px;
}
.complaint-modal {
  position: absolute;
  bottom: 0;
  left: 21%;
  width: 25%;
}
.btn-close {
  opacity: 1;
}
.dt-buttons,
.dataTables_filter,
.card-header-right-icon {
  display: none;
}
.whatsnew-btn:hover a {
  background-color: white;
  color: #7366ff !important;
}
.cstm-sidebar-padding {
  padding: 12px 20px;
  color: rgba(82, 82, 108, 0.8);
  font-weight: 500;
}
