.card-img {
  width: 100px;
  position: absolute;
  left: 8%;
  bottom: 8%;
}
.rating-tag {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #17335e;
  color: white;
}
.rating-icon {
  font-size: 12px;
  margin-left: 2px;
}
.price-tag {
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3px 0;
  border-radius: 5px;
  background-color: #b02545;
  color: white;
}
.verified-tag {
  font-size: 13px;
  padding: 0 0.5rem;
  width: fit-content;
  text-align: center;
  border-radius: 2px;
  color: white;
}
.view-card-button {
  outline: 1px solid #b02545 !important;
  color: black !important;
}
.view-card-button:hover {
  background: #254e58 !important;
  outline: none !important;
  color: white !important;
}
.cstm-circle-border {
  position: relative;
  width: 120px;
  height: 120px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/assets/border-img.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.progress-tooltip {
  display: none;
  opacity: 0;
}
.startup-progress-bar {
  transition: 0.3s all ease-in-out;
}
.startup-progress-bar:hover {
  .progress-tooltip {
    display: block;
    opacity: 1;
  }
}

.user-card {
  position: relative;
  width: 100%;
  height: max-content;
  padding: 1rem;
  border-radius: 10px;
  background: white;
  transition: all 0.3s ease-in;
}
.user-card:hover {
  transform: scale(1.05);
}
.startup-card-bg {
  background: url("/public/assets/blue-card-bg.png") no-repeat center center;
  background-size: cover;
}

.startup-flag {
  position: absolute;
  left: 60%;
  bottom: 0;
  transform: translate(50%, 0px);
  height: 33px;
  width: 33px;
  border-radius: 50%;
}
.profile-percentage {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  border: 6px solid lightgray;
}
.percent {
  position: absolute;
  top: 10px;
  right: 10px;
}
.startup-card-bg .circle-svg {
  position: relative;
  width: 55px;
  height: 55px;
  transform: rotate(-90deg);
}

.startup-card-bg .circle-svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 10;
  stroke-linecap: round;
}

.startup-card-bg .circle-svg circle:last-of-type {
  stroke-dasharray: 150px;
  stroke-dashoffset: calc(150px - (150px * var(--percent)) / 100);
  stroke: #ff9a41;
}
.startup-card-bg .circle-svg.percent-90 circle:last-of-type {
  stroke: #0faa51;
}
.startup-card-bg .circle-svg.percent-70 circle:last-of-type {
  stroke: #2b95e9;
}

.startup-card-bg .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 12px;
}
.card-profile-img {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
}
.mentor-fee-tag {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 19px;
  border-radius: 0 8px 8px 0;
  background-color: #17335e;
  color: white;
}
.industry-tags {
  padding: 3px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
}
.investor-tags {
  border: 1px solid #f6f6f6;
  background-color: #edf5fc;
}
.mentor-tags {
  background-color: #f1f2f6;
  color: #757998;
  border: 1px solid #f6f6f6;
}
.welcome-card-bg {
  background-image: url("/public/assets/welcome-card-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  color: #fff;
  padding: 8px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.balance-card-bg {
  background-color: #3e3c8f;
  color: #fff;
  border-radius: 15px;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.profile-percent-circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-percent-circle .circle-svg {
  position: relative;
  width: 110px;
  height: 110px;
  transform: rotate(-90deg);
}
.profile-percent-circle .circle-svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #5956ff;
  stroke-width: 12;
  stroke-linecap: round;
}
.profile-percent-circle .circle-svg circle:last-of-type {
  stroke-dasharray: 282.74; /* Circumference of the circle */
  stroke-dashoffset: calc(282.74 - (282.74 * var(--percent)) / 100);
  stroke: #ff9a41;
}
.profile-percent-circle .circle-svg.percent-90 circle:last-of-type {
  stroke: #0faa51;
}
.profile-percent-circle .circle-svg.percent-70 circle:last-of-type {
  stroke: #2b95e9;
}
.profile-percent-circle .number {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -55%);
  font-weight: 600;
  font-size: 17px;
}
@media screen and (min-width: 640px) and (max-width: 768px) {
  .welcome-card-bg {
    background-image: none;
    background-color: #5956ff;
  }
}
@media screen and (max-width: 768px) {
  .balance-card-bg {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }
}
