p,
span,
li {
  font-size: 14px;
}

.input:focus,
.form-control,
.form-select,
.btn,
.btn-primary:focus {
  box-shadow: none !important;
}

.btn {
  transition: all 0.2s ease;
}
.btn:active {
  transform: scale(0.9);
}
.left-half {
  position: fixed;
  width: 20%;
  z-index: 1;
}
.right-half {
  position: relative;
}
.industry-tags {
  background-color: transparent;
  padding: 0px 10px;
  border-radius: 15px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #797979;
  font-size: 10px;
}
.industry-tags-1 {
  padding: 0px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid black;
  font-size: 10px;
}
.outline-tags {
  background-color: transparent;
  border: 1px solid #17335f;
  color: #17335f;
  border-radius: 10px;
  padding: 2px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (min-width: 1024px) {
  .right-half {
    position: relative;
    left: 25%;
  }
}

.orange-badge {
  color: #c8691b;
  background-color: #fff4de;
  border: 1px solid #fb923c;
  border-radius: 6px;
  font-weight: 600;
}
.green-badge {
  color: #27b65b;
  background-color: #dcfce7;
  border: 1px solid #4ade80;
  border-radius: 6px;
  font-weight: 600;
}
.indigo-badge {
  color: #303cae;
  background-color: #f3e8ff;
  border: 1px solid #818cf8;
  border-radius: 6px;
  font-weight: 600;
}
.blue-badge {
  color: #3f7ecc;
  background-color: #bfdbfe;
  border: 1px solid #60a5fa;
  border-radius: 6px;
  font-weight: 600;
}
.red-badge {
  color: #a42323;
  background-color: #ffe2e5;
  border: 1px solid #f36363;
  border-radius: 6px;
  font-weight: 600;
}
.yellow-badge {
  color: #a68b1f;
  background-color: #fff8c8;
  border: 1px solid #facc15;
  border-radius: 6px;
  font-weight: 600;
}

thead {
  background-color: #17335e !important;
  color: white !important;
}
tbody tr:nth-child(even) {
  background-color: #17335f12;
}
.main-bg-img {
  background-image: url("/public/assets/background.png");
  background-size: cover;
  background-position: center;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}
