.green-icon {
  background: linear-gradient(310.31deg, #018400 14.71%, #7ffffb 100%);
}
.pink-icon {
  background: linear-gradient(310.31deg, #ff3364 14.71%, #ff7f9e 100%);
}
.orange-icon {
  background: linear-gradient(297.08deg, #ff9e2a -4.53%, #ffa941 98.25%);
}
.blue-icon {
  background: linear-gradient(310.31deg, #4333ff 14.71%, #7f95ff 100%);
}
.purple-icon {
  background-image: linear-gradient(144.16deg, #978cfb 19.06%, #f7f7f9 79.03%);
}
.warning-icon {
  background-image: linear-gradient(
    144.16deg,
    #fff4e6 19.06%,
    #fff1de 79.03%
  ) !important;
}
.course-icon {
  position: relative;
  width: 54px;
  height: 54px;
  clip-path: polygon(
    40% 7.67949%,
    43.1596% 6.20615%,
    46.52704% 5.30384%,
    50% 5%,
    53.47296% 5.30384%,
    56.8404% 6.20615%,
    60% 7.67949%,
    81.65064% 20.17949%,
    84.50639% 22.17911%,
    86.97152% 24.64425%,
    88.97114% 27.5%,
    90.44449% 30.6596%,
    91.34679% 34.02704%,
    91.65064% 37.5%,
    91.65064% 62.5%,
    91.34679% 65.97296%,
    90.44449% 69.3404%,
    88.97114% 72.5%,
    86.97152% 75.35575%,
    84.50639% 77.82089%,
    81.65064% 79.82051%,
    60% 92.32051%,
    56.8404% 93.79385%,
    53.47296% 94.69616%,
    50% 95%,
    46.52704% 94.69616%,
    43.1596% 93.79385%,
    40% 92.32051%,
    18.34936% 79.82051%,
    15.49361% 77.82089%,
    13.02848% 75.35575%,
    11.02886% 72.5%,
    9.55551% 69.3404%,
    8.65321% 65.97296%,
    8.34936% 62.5%,
    8.34936% 37.5%,
    8.65321% 34.02704%,
    9.55551% 30.6596%,
    11.02886% 27.5%,
    13.02848% 24.64425%,
    15.49361% 22.17911%,
    18.34936% 20.17949%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.cstm-pos {
  bottom: 40px !important;
  width: 90% !important;
  left: 13px !important;
}
.cstm-margin {
  margin-right: 10px;
}
.cstm-hover:hover {
  cursor: pointer;
}
.cstm-hover:hover i {
  color: #9086ff !important;
}
.dropdown-menu a {
  opacity: 1 !important;
  color: #565b65 !important;
}
.dropdown-menu li:hover a {
  background-color: #f1f0ff !important;
  color: #7366ff !important;
}
.activity-dropdown-btn {
  background-color: #7366ff !important;
  color: white !important;
}
.activity-dropdown-btn:hover {
  background-color: #f1f0ff !important;
  color: #7366ff !important;
}
.modal-padding {
  padding-right: 20px;
  padding-left: 20px;
}
.complaint-modal {
  position: absolute;
  bottom: 0;
  left: 21%;
  width: 25%;
}
.btn-close {
  opacity: 1;
}
.dt-buttons,
.dataTables_filter,
.card-header-right-icon {
  display: none;
}
.whatsnew-btn a {
  background-color: white;
  color: #7366ff !important;
}
.cstm-sidebar-padding {
  padding: 12px 20px;
  color: rgba(82, 82, 108, 0.8);
  font-weight: 500;
}
.website-info-card-btn {
  font-size: 14px;
}
.cstm-svg-pos svg g:first-child {
  transform: translate(70, 40) !important;
}
.stat-cards {
  overflow: hidden;
}
.stat-cards:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}
.stat-cards .bg-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  right: -8px;
  top: -5%;
  position: absolute;
}
.primary .bg-gradient {
  background-image: linear-gradient(
    144.16deg,
    rgba(115, 102, 255, 0.1) 19.06%,
    rgba(115, 102, 255, 0) 79.03%
  ) !important;
}
.warning .bg-gradient {
  background-image: linear-gradient(
    144.16deg,
    rgba(255, 170, 5, 0.1) 19.06%,
    rgba(255, 170, 5, 0) 79.03%
  ) !important;
}
.success .bg-gradient {
  background-image: linear-gradient(
    144.16deg,
    #c8ffc9 19.06%,
    #fffcfc 79.03%
  ) !important;
}

.claim-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-radius: 10px;
  background: linear-gradient(45deg, #17335e, #031738);
  color: #fff;
}
.claim-btn {
  background: linear-gradient(45deg, #ffd700, #fff3b5) !important;
  font-weight: 500 !important;
  padding: 0.25rem 0.5rem !important;
}
.single-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e4e4e4;
  padding-top: 5px;
}
.single-row:first-child {
  border-top: none;
}

@media only screen and (max-width: 1399px) {
  .stat-cards .bg-gradient {
    width: 50px;
    height: 50px;
  }
}
