@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

@layer components {
  .card {
    background-color: white;
    border-radius: 6px;
    padding: 12px;
  }
  .button {
    transition: all 0.2s ease;
  }
  .button:active {
    transform: scale(0.9);
  }
  .button:hover {
    animation: push 0.5s linear 1;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f3f3f3; */
  background: #f8f8f8;
}

code {
  font-family: "Montserrat", sans-serif;
}
input {
  outline: none;
  font-size: 14px;
}
textarea,
select {
  font-size: 14px;
}
label {
  font-weight: 500;
  font-size: 14px;
}
.required::after {
  content: "*";
  color: red;
  font-size: 16px;
}

.slick-slide > div {
  margin: 0 3px;
}
.slick-slider > button {
  display: none !important;
}
.slick-dots {
  bottom: -15px !important;
}
