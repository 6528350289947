.ticketModal {
  width: 350px;
  position: fixed;
  left: 35%;
  bottom: -17%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.closeBtn {
  background-color: orange;
  color: white;
  padding: 0 6px;
  border-radius: 5px;
}
