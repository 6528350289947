.description-text {
  --max-lines: 2;
  --line-height: 1.5;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height) !important;
  overflow: hidden;
  position: relative;
}

.description-text:has(+ .expand-btn:not(:checked))::before {
  content: "";
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, white);
}

.expand-btn {
  appearance: none;
  cursor: pointer;
  color: #008dff;
  font-size: 13px;
}

.expand-btn::before {
  content: "Read More...";
}
.expand-btn:checked::before {
  content: "Read Less...";
}

.description-text:has(+ .expand-btn:checked) {
  max-height: none;
}
