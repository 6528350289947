textarea:focus-visible {
  outline: none;
}
textarea {
  width: 100%;
}
.alert {
  z-index: 1000;
  width: 75%;
  right: 25px;
}
.review-btn {
  position: absolute;
  bottom: 10px;
  right: 50px;
}
.completion-form-body {
  background-size: contain;
}
.completion-form {
  margin-top: 80px;
  margin-left: 265px;
}
.cstm-bg {
  background-repeat: no-repeat;
  height: 100vh;
}
.delete-account-btn,
.edit-account-details {
  display: none;
}
.profile-detail-card {
  transition: all 0.3s ease-in-out;
}
.profile-detail-card:hover {
  .delete-account-btn,
  .edit-account-details {
    display: inline;
  }
}
.profile-img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: white;
  padding: 8px;
}

.outline-btn {
  outline: 1px solid #f97316;
  padding: 0 8px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.outline-btn:hover {
  background-color: #f97316;
  color: white;
}
.img-icon {
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .profile-btns {
    top: unset;
    position: absolute;
    bottom: -10px;
  }
  .profile-img {
    position: absolute;
    border-radius: 50%;
    top: -4rem;
    height: 140px;
    width: 140px;
    background-color: white;
    padding: 8px;
    left: 55px;
  }
  .img-icon {
    position: absolute;
    bottom: 0;
    top: 50px;
    left: 55px;
  }
}
