.pricing-card {
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 18px;
  transition: all 0.2s ease-in;
}
.pricing-card:hover {
  transform: scale(1.02);
  .pricing-card-icon {
    display: inline-block;
  }
}
.pricing-card-icon {
  display: none;
  position: absolute;
  top: -8px;
  right: 3px;
  cursor: pointer;
}
