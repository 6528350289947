.modalContainer {
  max-width: 550px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  z-index: 100;
}
.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.content {
  text-align: center;
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
}
.modal-btn {
  border-radius: 4px !important;
}
.list-style {
  list-style: disc;
}
