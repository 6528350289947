.bg-color {
  background-color: #17335e;
}
.connect-btn {
  right: 15px;
}
.connect-btn:hover {
  background-color: #b02545;
}
.disconnect-btn {
  background-color: #b02545;
  right: 15px;
  transition: all 0.2s ease;
}
.disconnect-btn:hover {
  background-color: #17335e;
}
.disconnect-btn:active {
  transform: scale(0.9);
}
.cstm-font-size {
  font-size: 16px;
}
.icon-color {
  color: #a2a2a2;
}
